<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-dept-multi type="search" label="LBLDEPT" name="deptList" v-model="searchParam.deptList" />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
            <c-datepicker
              required
              :range="true"
              label="LBLPERIOD"
              type="year"
              name="period"
              defaultStart="-2y"
              defaultEnd="2y"
              v-model="period"
            />
          </div> -->
        </template>
      </c-search-box>
      <div class="col-12">
        <apexchart 
          ref="chart" 
          height="300" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
      <div class="col-12">
        <c-table
          ref="table"
          title="통계조사표"
          tableId="table"
          :merge="grid.merge"
          gridHeight="360px"
          :columns="grid.columns"
          :data="grid.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'testimonial-person-graph1',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        deptCd: '',
        deptList: '',
        startYmd: '',
        endYmd: '',
      },
      year: ['', ''],
      chart: {
        chartOptions: {
          title: {
            text: '부서별 연간 유소견자 수'
          },
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: true
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '명'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 명"
              }
            }
          }
        },
        chartWidth: '80%',
        series: [
          // {
          //   name: '생산팀A',
          //   data: [2, 6, 6, 6, 3]
          // },
          // {
          //   name: '생산팀B',
          //   data: [5, 15, 8, 1, 2]
          // },
          // {
          //   name: '안전환경팀',
          //   data: [1, 1, 1, 1, 2]
          // },
          // {
          //   name: '기술팀A',
          //   data: [2, 4, 6, 1, 5]
          // },
          // {
          //   name: '기술팀B',
          //   data: [12, 14, 16, 11, 15]
          // },
        ],
      },
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '유소견자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'appointDate',
            field: 'appointDate',
            label: '지정일',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'judgmentNames',
            field: 'judgmentNames',
            label: '판정(질환)',
            align: 'left',
            style: 'width: 500px',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.hea.checkup.suspect.graph.dept.url;
      // 년도 설정
      let years = [];
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.chart.chartOptions.xaxis.categories = years;

      this.searchParam.startYmd = years[0];
      this.searchParam.endYmd = years[4];

      this.getList();
      this.setChart();
    },
    getList() {
      // if (this.period) {
      //   this.searchParam.startYmd = this.period[0];
      //   this.searchParam.endYmd = this.period[1];
      // } else {
      //   this.searchParam.startYmd = '';
      //   this.searchParam.endYmd = '';
      //   window.getApp.$emit('ALERT', {
      //     title: 'LBLGUIDE', // 안내
      //     message: '기간을 선택하세요.', // 기간을 선택하세요.
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return;
      // }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.setGraph();
      },);
    },
    setGraph() {
      let graphDatas = this.$_.clone(this.grid.data);
      this.chart.series = [];
      this.$_.forEach(graphDatas, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
        let idx = this.chart.chartOptions.xaxis.categories.indexOf(_item.appointYear)

        // 부서 중복 제거
        if (this.$_.findIndex(this.chart.series, { deptCd: _item.deptCd }) === -1) {
          this.chart.series.push({
            deptCd: _item.deptCd,
            name: _item.deptName,
            data: [0, 0, 0, 0, 0]
          })
        }
        this.chart.series[this.$_.findIndex(this.chart.series, { deptCd: _item.deptCd })].data[idx] ++;
      })
      this.setChart();
    },
    setChart() {
      setTimeout(() => {
        this.chart.chartWidth = '90%';
      }, 200);
      this.$refs['chart'].refresh();
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>